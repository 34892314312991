import React from 'react';
import config from '../../config';

export default function Recruit({ visible, setVisible }) {
  return (
    <section id="signup" className="signup-section">
      <div className="container">
        <div className="row">
          <div className="col-md-10 col-lg-8 mx-auto text-center">
            <div className="desc text-white text-left" style={{ whiteSpace: 'pre-line' }}>{config.careerTitle}</div>
            <a className="button primary" onClick={e => setVisible(!visible)}>
              지원하기
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
