import React from 'react';
import config from '../../config';

export default function Career() {
  return (
    <section className="contact-section bg-black">
      <div className="container">
        <p className="text-white">메디칼엑셀런스에서 나는,</p>

        <div className="row">
          <div className="col-md-3 mb-3 mb-md-0">
            <div className="card py-4 h-100">
              <div className="card-body text-center">
                <h4 className="text-uppercase m-0 font-italic">"이건 기본"</h4>
                <hr className="my-4" />
                <div className="small text-black-50">휴가는 눈치보지 않고</div>
              </div>
            </div>
          </div>

          <div className="col-md-3 mb-3 mb-md-0">
            <div className="card py-4 h-100">
              <div className="card-body text-center">
                <h4 className="text-uppercase m-0 font-italic">“유연근무제”</h4>
                <hr className="my-4" />
                <div className="small text-black-50">자유롭게 출퇴근</div>
              </div>
            </div>
          </div>

          <div className="col-md-3 mb-3 mb-md-0">
            <div className="card py-4 h-100">
              <div className="card-body text-center">
                <h4 className="text-uppercase m-0 font-italic">“재택근무제”</h4>
                <hr className="my-4" />
                <div className="small text-black-50">혼자서도 잘해요 경력이 쌓이면</div>
              </div>
            </div>
          </div>

          <div className="col-md-3 mb-3 mb-md-0">
            <div className="card py-4 h-100">
              <div className="card-body text-center">
                <h4 className="text-uppercase m-0 font-italic">“포상휴가제”</h4>
                <hr className="my-4" />
                <div className="small text-black-50">근속하면 특별대우!</div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-white mt-3">무엇보다 자유로운 분위기, 좋은 동료, 공부하고 도전하는 환경!</div>

        <div className="career-table list-group mt-10">
          <a href="https://www.dietitian.or.kr/work/news/kn_careers_view.do?idx=23703&rnum=30&curPage=1&searchKind=&searchWord=" className="list-group-item list-group-item-action">
            <div className="d-flex w-100 justify-content-between">
              <h5 className="mb-1">임상영양사 경력 모집 공고</h5>
              <small className="text-muted">진행중</small>
            </div>
            <p className="mb-1 text-black-50">
              헬스케어 전문가로서 IT기술을 접목한 분야에 관심을 가지고, 적극적으로 헬스케어서비스를 만들어갈 임상영양사를 모십니다.
            </p>
            <small className="text-muted">~ 2021.02.07</small>
          </a>
          {/* <a target="_blank" rel="noopener noreferrer" href="http://www.saramin.co.kr/zf_user/jobs/relay/view?isMypage=no&rec_idx=38895951&recommend_ids=eJxt0MsNxDAIBNBq9o6BgeGcQtx%2FF%2BtIiYylHJ%2F5DLKRhJRNFH552U1QPjj9fSg92h%2FuuqPPR9A3y1KiVXOgOgXZZ0WPU7hiGvMIghk3jekxE3xyEa4zxV7asE6t6ATHZrrD96rUcvbNvj5oNf8BjflJHg%3D%3D&view_type=search&searchword=%EB%A9%94%EB%94%94%EC%B9%BC%EC%97%91%EC%85%80%EB%9F%B0%EC%8A%A4&searchType=default_mysearch&gz=1&t_ref_content=generic&t_ref=search&paid_fl=n#seq=0" className="list-group-item list-group-item-action">
            <div className="d-flex w-100 justify-content-between">
              <h5 className="mb-1">디지털헬스케어 서비스 PM 모집</h5>
              <small className="text-muted">진행중</small>
            </div>
            <p className="mb-1 text-black-50">
              만성질환자를 위한 건강관리 서비스  PM(Product Manager)를 모십니다.<br/>
              데이터 중심의 변화하는 시대에, 좀 더 혁신적이고 가치 있는 헬스케어 서비스를 같이 만들어나갈 분을 모십니다.
            </p>
            <small className="text-muted">~ 2020.12.11</small>
          </a>
          <a target="_blank" rel="noopener noreferrer" href="http://www.saramin.co.kr/zf_user/jobs/relay/view?isMypage=no&rec_idx=39166609&recommend_ids=eJxd0MkRg0AMBMBo%2FNfonrcD2fyzMFCAwM%2FW6NhaI8hwW8H41NceXH4UxEJNt0L%2B8cxRVs0ViZ1NlUZeJATlNamLNia1aOG9G%2BmanKeksP1id4OGVZ4nFRkXiUARQ9cXA6HvZnuwgs6botuqmEO1fYlMWpJdM6vlehz6AQuMSGw%3D&view_type=search&searchword=%EB%A9%94%EB%94%94%EC%B9%BC%EC%97%91%EC%85%80%EB%9F%B0%EC%8A%A4&searchType=search&gz=1&t_ref_content=generic&t_ref=search&paid_fl=n#seq=0" className="list-group-item list-group-item-action">
            <div className="d-flex w-100 justify-content-between">
              <h5 className="mb-1">임상사업부 CTA 모집 공고</h5>
              <small className="text-muted">진행중</small>
            </div>
            <p className="mb-1 text-black-50">
              임상연구 관련 문서, 물품 관리 및 임상 운영 지원 업무를 수행할 열정 넘치는 분을 모십니다. 
            </p>
            <small className="text-muted">~ 2020.12.31</small>
          </a> */}
          <a target="_blank" rel="noopener noreferrer" href="https://www.saramin.co.kr/zf_user/jobs/relay/view?isMypage=no&rec_idx=39246656&recommend_ids=eJxV0MERAzEIA8Bq8gdJGHinEPffRS53k8F5LsgwmC1VMnb0euWbB7eegmdo7Vh%2B07O9ayhzTlcsC12v4yGk5tALa7rskuVwdVUNaVb4Iw%2Bi%2BwijAc4oAHks8u9VMYxrc084ifSdej4ARJid1BX%2BkQavO%2FwBVFVEqw%3D%3D&view_type=search&searchword=%EB%A9%94%EB%94%94%EC%B9%BC%EC%97%91%EC%85%80%EB%9F%B0%EC%8A%A4+cra&searchType=quick&gz=1&t_ref_content=quick_search&t_ref=quick_search#seq=0" className="list-group-item list-group-item-action">
            <div className="d-flex w-100 justify-content-between">
              <h5 className="mb-1">임상사업부 CRA 모집 공고</h5>
              <small className="text-muted">진행중</small>
            </div>
            <p className="mb-1 text-black-50">
              임상시험의 질을 높이고 적극적으로 이끌어 나갈 능력과 경험을 가진 분을 모십니다.
            </p>
            <small className="text-muted">~ 2021.02.08</small>
          </a>
        </div>

      </div>
    </section>
  );
}
