import React from 'react';

import Layout from '../components/Layout';

import config from '../../config';
import Footer from '../components/Footer';
import Career from '../components/Career';
import Recruit from '../components/Recruit';
import Header from '../components/Header';

import bottle from '../assets/images/bottle.jpg';
import water from '../assets/images/water-recreation.jpg';
import report from '../assets/images/report.jpg';
import icared from '../assets/images/icared_logo.png';
import myhealth from '../assets/images/myhealth_logo.png';

const { useState } = React;

const IndexPage = () => {
  const [ visible, setVisible ] = useState(false);

  return (
    <Layout>
      <Header />

      <header className="masthead">
        <div className="container d-flex h-100 align-items-center">
          <div className="mx-auto text-center">
            <h1 className="mx-auto my-0 text-uppercase">{config.heading}</h1>
            <h2 className="text-white-50 mx-auto mt-2 mb-2">
              {config.subHeading}
            </h2>
          </div>
        </div>
      </header>

      <section id="about" className="about-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 mx-auto">
              <h4 className="text-white mb-4">
                {config.aboutTitle}
                <span className="text-white-50">는</span>
              </h4>
              <p className="text-white-50">{config.aboutDesc}</p>
            </div>
          </div>
          <img src={report} className="img-fluid" alt="" />
        </div>
      </section>

      <section id="services" className="projects-section bg-light">
        <div className="container">
          <div className="row align-items-center no-gutters mb-4 mb-lg-5">
            <div className="col-xl-8 col-lg-7">
              <img className="img-fluid mb-3 mb-lg-0" src={water} alt="" />
            </div>
            <div className="col-xl-4 col-lg-5">
              <div className="featured-text text-center text-lg-left">
                <h4>{config.serviceTitle1}</h4>
                <p className="text-black-50 mb-0 text-left">{config.serviceDesc1}</p>

                <div className="site mt-5">
                  <div className="row text-right">
                    <div className="col">
                      <p style={{ color: '#64a19d', fontSize: '13px', marginBottom: 3 }}>사이트 바로가기</p>    
                    </div>
                  </div>
                  <div className="row justify-content-end">
                    <div className="col-lg-4 shadow-sm p-2 m-1 text-center">
                      <a href="http://www.icared.co.kr" target="_blank" rel="noopener noreferrer" >
                        <img className="img-fluid" src={icared} alt="아이케어디" style={{ width: 100 }} />
                      </a>
                    </div>
                    <div className="col-lg-4 shadow-sm p-2 m-1 text-center">
                      <a href="http://www.my-health.co.kr" target="_blank" rel="noopener noreferrer">
                        <img className="img-fluid" src={myhealth} alt="마이헬스" style={{ width: 100 }} />
                      </a>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div className="row justify-content-center no-gutters mb-5 mb-lg-0">
            <div className="col-lg-6">
              <img className="img-fluid" src={bottle} alt="" />
            </div>
            <div className="col-lg-6">
              <div className="bg-black text-center h-100 project">
                <div className="d-flex h-100">
                  <div className="project-text w-100 my-auto text-center text-lg-left">
                    <h4 className="text-white">{config.serviceTitle2}</h4>
                    <p className="mb-0 text-white-50 text-left">{config.serviceDesc2}</p>
                    <hr className="d-none d-lg-block mb-0 ml-0" />

                    <div className="cro text-white mt-3 container">
                      <div className="row">
                        <div className="col">
                          <p>임상시험 관련 문의사항이나 상담이 필요하신 분은 아래로 연락주십시오.</p>
                        </div>
                      </div>
                      <div className="row align-items-center justify-content-between">
                        <div className="col-xm-6">
                          <div><a href={`mailto:CRO@mediex.co.kr`}>CRO@mediex.co.kr</a></div>
                          <div>TEL: 02-3482-7861</div>
                        </div>
                        <div className="col-xm-6 text-right">
                          <a href="https://forms.gle/tftX5hXCHARXEPWF9" target="_blank" rel="noopener noreferrer" className="button primary">
                            견적의뢰
                          </a>
                        </div>
                      </div>                      
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Recruit visible={visible} setVisible={setVisible} />

      {visible && <Career />}
      
      <Footer />
    </Layout>
  );
}

export default IndexPage;
