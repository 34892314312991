import React from 'react';

import config from '../../config';

export default function Footer() {
  return (
    <footer id="contact" className="bg-black small text-white-50">
      <div className="container">
        <div className="row">
          <div className="col align-self-center text-center">
            Copyright &copy; MedicalExcellence Inc. All rights reserved.
          </div>
          <div className="col text-right">
            <ul className="list-unstyled">
              <li><a href={`mailto:${config.email}`}>{config.email}</a></li>
              <li>{config.address}</li>
              <li>TEL: {config.phone}</li>
              <li>FAX: {config.fax}</li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}
